<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :aria-labelledby="name"
    :viewBox="!viewBox ? `0 0 ${width} ${height}` : viewBox"
    role="presentation"
    :fill="color"
  >
    <title :id="name" lang="en">{{ name }} icon</title>
    <g>
      <slot />
    </g>
  </svg>
</template>

<script>
// https://v3.ko.vuejs.org/cookbook/editable-svg-icons.html#기본-예제
export default {
  name: 'IconBase',
  props: {
    name: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
    viewBox: {
      type: String,
      required: false,
    },
  },
};
</script>
