
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Container',
  props: {
    width: {
      type: Number,
      isRequired: false,
    },
    className: {
      type: String,
      isRequired: false,
    },
    style: {
      type: Object,
      isRequired: false,
    },
  },
});
