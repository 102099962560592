export default [
  { value: '002', label: '산업은행' },
  { value: '003', label: '기업은행' },
  { value: '004', label: '국민은행' },
  { value: '007', label: '수협중앙회' },
  { value: '011', label: '농협은행' },
  { value: '012', label: '지역농축협' },
  { value: '020', label: '우리은행' },
  { value: '023', label: 'SC은행' },
  { value: '027', label: '한국씨티은행' },
  { value: '031', label: '대구은행' },
  { value: '032', label: '부산은행' },
  { value: '034', label: '광주은행' },
  { value: '035', label: '제주은행' },
  { value: '037', label: '전북은행' },
  { value: '039', label: '경남은행' },
  { value: '045', label: '새마을금고연합회' },
  { value: '048', label: '신협' },
  { value: '050', label: '저축은행' },
  { value: '054', label: 'HSBC은행' },
  { value: '055', label: '도이치은행' },
  { value: '057', label: '제이피모간체이스은행' },
  { value: '060', label: 'BOA은행' },
  { value: '061', label: '비엔피파리바은행' },
  { value: '062', label: '중국공상은행' },
  { value: '064', label: '산림조합' },
  { value: '067', label: '중국건설은행' },
  { value: '071', label: '우체국' },
  { value: '081', label: '하나은행' },
  { value: '088', label: '신한은행' },
  { value: '089', label: '케이뱅크' },
  { value: '090', label: '카카오뱅크' },
  { value: '209', label: '유안타증권' },
  { value: '218', label: 'KB증권' },
  { value: '227', label: 'KTB투자증권' },
  { value: '238', label: '미래에셋대우' },
  { value: '240', label: '삼성증권' },
  { value: '243', label: '한국투자증권' },
  { value: '247', label: 'NH투자증권' },
  { value: '261', label: '교보증권' },
  { value: '262', label: '하이투자증권' },
  { value: '263', label: '현대차증권' },
  { value: '264', label: '키움증권' },
  { value: '265', label: '이베스트투자증권' },
  { value: '266', label: 'SK증권' },
  { value: '267', label: '대신증권' },
  { value: '269', label: '한화투자증권' },
  { value: '270', label: '하나금융투자' },
  { value: '278', label: '신한금융투자' },
  { value: '279', label: 'DB금융투자' },
  { value: '280', label: '유진투자증권' },
  { value: '287', label: '메리츠종합금융증권' },
  { value: '288', label: '카카오페이증권' },
  { value: '290', label: '부국증권' },
  { value: '291', label: '신영증권' },
  { value: '292', label: '케이프투자증권' },
  { value: '294', label: '펀드온라인코리아' },
];
