<template>
  <button :class="[className]" @click="handleClickButton">
    <slot></slot>
  </button>
  <input type="file" @change="handleChange" ref="inputEl" class="hidden" />
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';

export default defineComponent({
  name: 'HiddenFileInput',
  props: {
    className: {
      type: String,
      required: false,
    },
    onChange: Function as PropType<(e) => void>,
  },
  setup(props) {
    const inputEl = ref(null);

    const handleChange = (e) => {
      if (props.onChange) {
        props.onChange(e);
      }
    };

    const handleClickButton = () => {
      console.log('inputEl', inputEl.value);
      if (inputEl.value) {
        (inputEl.value as any).click();
      }
    };

    return {
      handleChange,
      handleClickButton,
      inputEl,
    };
  },
});
</script>

<style lang="scss" scoped></style>
