<template>
  <div
    :class="[className, 'mx-auto bg-white p-10']"
    :style="{ width: width + 'px' || '1560px', ...style }"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Container',
  props: {
    width: {
      type: Number,
      isRequired: false,
    },
    className: {
      type: String,
      isRequired: false,
    },
    style: {
      type: Object,
      isRequired: false,
    },
  },
});
</script>

<style lang="scss" scoped></style>
