import axios from 'axios';

type Option = { files: FileList };

const uploadSignupImageFiles = async ({ files }: Option): Promise<string[]> => {
  console.log('files', files);
  console.log('files', files.length);

  try {
    let uploadedImageUrls: string[] = [];

    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append('file', files[i]);

      const { data: imageUrl } = await axios.post(
        process.env.VUE_APP_API_HOST + '/api/partner/signup-upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      uploadedImageUrls = [...uploadedImageUrls, imageUrl];
    }

    return uploadedImageUrls;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default uploadSignupImageFiles;
